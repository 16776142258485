.scene{
    
    background: linear-gradient(rgb(9, 18, 41), #110735);
    width: 100%;
    height: 90vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}


.scene .rocket{

    position: relative;
    animation: yolo 0.2s ease infinite;

}

@keyframes yolo{
    0%,100%{
        transform: translateY(-2px);
    }
    50%{
        transform: translateY(2px);
    }
}




.scene .rocket::before{
    content: '';
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 200px;
    background: linear-gradient(#00d0ff, transparent);
}

.scene .rocket::after{
    content: '';
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 200px;
    background: linear-gradient(#00d0ff, transparent);
    filter: blur(20px);
}
