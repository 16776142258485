.chatBoxes{
    -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
}

.actualChat{
    max-height: 90%;
    height:90%;
}


.activeChat{
    /* color: red; */
    background-color: #1c233f;
    border-radius: 5px;

}

