.candidateID-area{
    justify-content:"center";
    align-items:"center";
    text-align: center;
  }
  
  .basic-details{
    display: flex;
    justify-content: space-between;
    font-size: small;
  }


  .arrow-left
  {
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
 
