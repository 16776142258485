.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  text-decoration: none;
}

 

.dropdown-toggle:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  padding: 8px;
  border: 1px solid #ccc;
  z-index: 1;
}

.dropdown-menu.show {
  display: block;
}
