.date-input-container {
    position: relative; /* Ensure the container allows absolute positioning */
  }
  
  .input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-with-icon input {
    padding-right: 40px;
  }
  
  .calendar-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #6c757d;
  }
  
  .calendar-icon:hover {
    color: #f54394;
  }
  
  .react-datepicker {
    z-index: 1000; /* Set high z-index for date picker */
    position: absolute; /* Ensures it floats above */
  }
  