*,
*:before,:after {
  box-sizing: border-box;
  outline: none;
}
$bgColor: #020438;
$boxColor: #020438;
$waterFColor: #458af7;
$waterBColor: #74a6f5;
$borderColor: #b9c0d3;

.box {
  height: 225px;
  width: 225px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: $boxColor;
  border: 2px solid $borderColor;
  border-radius: 100%;
  overflow: hidden;
  .percent {
    position: absolute;
    left: 0;
    top:-25px;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 64px;
  }

  .description {
    position: absolute;
    left: 0;
    top: 45px;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
    background: $waterFColor;
    transition: all 0.3s;
    &_wave {
      width: 200%;
      position: absolute;
      bottom: 100%;
      &_back {
        right: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }
      &_front {
        left: 0;
        fill: $waterFColor;
        margin-bottom: -1px;
        animation: wave-front 0.7s infinite linear;
      }
    }
  }
}
@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}
