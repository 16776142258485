.dropZoneInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dzu-inputLabel {
  color: inherit !important;
}

.modal-content {
  color: dimgray !important;
  background-color: whitesmoke !important;
}

.minMaxHeight {
  /* min-height: 80vh; */
  max-height: fit-content;
}

