.main {
  font-size: 14px;
  justify-content: center;
  display: flex;
  flex: 1;
  height: auto;
}
.sub-1 {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.vl {
  border-left: 2px solid #273055;
  height: 100%;
}
.test{
  background-color: blueviolet;
  justify-content: center;
  align-items: baseline;
  display: flex;
}
.candidate-active{
  height: 10px;
  width:10px;
  border-radius: 5px;
  background-color: #31c971;
  position: absolute;
  left: 50px;
  top:3px;
}
.candidate-de-active{
  height: 10px;
  width:10px;
  border-radius: 5px;
  background-color: #e91e63;
  position: absolute;
  left: 50px;
  top:3px;
}
.candidate-doment{
  height: 10px;
  width:10px;
  border-radius: 5px;
  background-color: #f4c414;
  position: absolute;
  left: 50px;
  top:3px;
}