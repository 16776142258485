.booking-table-container {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  border: 2px solid green;
  /* border-right: 0px; */
  border-start-end-radius: 5px;
  /* border-radius: 5px; */

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.booking-table-container-wide {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  /* border: 2px solid green; */
  border-left: 0px;
  border-start-end-radius: 5px;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.booking-table-container-small {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  border: 2px solid green;
  border-right: 0px;
  border-start-end-radius: 5px;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

/* .booking-table-container-small,
.booking-table-container-wide {
  max-height: 430px;
  overflow-y: auto;
} */
/* .scrollable-container {
  overflow: auto;
  max-height: 400px;
} */
.booking-table-container-small table,
.booking-table-container-wide table {
  border: 2px solid green;
  
}

.scrollable-container {
  overflow-y: auto;
  max-height:auto; 
  border: 2px solid green; 
  
}





.booking-td,
.booking-th {
  white-space: nowrap;
  padding: 10px 20px;
  font-family: Arial;
  border: 1px solid rgb(40, 53, 63);
}

.booking-th-options {
  white-space: nowrap;
  padding: 3px;
  position: sticky;
  /* margin: 3px; */
  font-family: Arial;
  border-bottom: 1px solid rgb(40, 53, 63);
  border-top: 3px solid#535c78;
  border-left: 2px solid#535c78;
}

.booking-tr-th-child-one {
  position: sticky;
  width: 200px;
  left: 0;
  z-index: 10;
  background: #161d33;
}
.booking-td-th-child-one {
  position: sticky;
  width: 170px;
  left: 0;
  z-index: 10;
  background: #192039;
}

.booking-tr-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #535c78;
}
.booking-td-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #161d33;
}

.booking-tr-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #535c78;
}

.groupSearch-tr-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #535c78;
}
.booking-td-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #161d33;
}

.booking-container {

  position: relative;

}

.booking-position-sticky {
  position: sticky;
  top: 0;
  z-index: 100; 
 
}

 
.groupSearch-td-th-child-three-HR {

  z-index: 10;
  background: #161d33;
}

.booking-tr-th-child-one {
  z-index: 11;
}

.booking-tr-th-child-two {
  z-index: 12;
}

.booking-tr-th-child-three {
  z-index: 13;
}

.booking-tr-th-child-four {
  z-index: 14;
  background: #161d33;
}

.booking-tr-th {
  position: sticky;
  top: 0;
  z-index: 9;

}

td.last {
  min-width: 100px;

  white-space: nowrap;
  overflow-x: auto;
}

.groupSearch-th {
  background: #161d33;
}
.groupSearch-td {
  background: #161d33;
}

/* white:---------------------------------------------------------------------------------- */
.w-groupSearch-th {
  background: #f5f5f6;
}

.w-groupSearch-td {
  background: #161d33;
}

.w-booking-th-options {
  white-space: nowrap;
  padding: 3px;
  /* margin: 3px; */
  font-family: Arial;
  

  border-bottom: 1px solid #cacaca;
  border-top: 3px solid #cacaca;
  border-left: 2px solid #cacaca;
  border-right: 3px solid #cacaca;
  background: #f5f5f6 !important;
}

.w-booking-td,
.w-booking-th {
  white-space: nowrap;
  padding: 10px 20px;
  font-family: Arial;
  border: 1px solid rgb(40, 53, 63);
}

.w-booking-tr-th-child-one {
  position: sticky;
  width: 100%;
  left: 0;
  z-index: 10;
  background: #f5f5f6;
}
.w-booking-td-th-child-one {
  position: sticky;
  width: 100%;
  left: 0;
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #f5f5f6;
}
.w-booking-td-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  background: #f5f5f6;
}

.w-groupSearch-tr-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-td-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #f5f5f6;
}

.w-groupSearch-td-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-one {
  z-index: 11;
}

.w-booking-tr-th-child-two {
  z-index: 12;
}

.w-booking-tr-th-child-three {
  z-index: 13;
}

.w-booking-tr-th-child-four {
  z-index: 14;
  
}

.w-booking-tr-th {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #ffffff;
}


.booking-table-container {
  overflow: auto;
}
/* important */
.booking-tr-th {
  position: sticky;
  top: 0;

  z-index: 10;
}
/* important */

.booking-td-th-child-one,
.booking-td-th-child-two,
.booking-td-th-child-three {
  position: sticky;


  z-index: 9;
}

