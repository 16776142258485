.sidebar {
  margin: 0;
  padding: 0;
  width: 248px;

  position: absolute;
  height: 50%;
  overflow: auto;
}
.dark-mode {
  background-color: black;
  color: white;
}
.sidebar span {
  display: block;
  padding: 9px;
  text-decoration: none;
  margin-left: 1%;
}

.sidebar .fa {
  font-size: 20px;
  margin-right: 10%;
  color: white;
}

.sidebar span:hover:not(.active) {
  /* background-color: #888888; */
  color: white;
  cursor: pointer;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: absolute;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
