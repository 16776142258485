.customAlignments {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  max-height: 20px;
  width: 100px;
}

.customAlignmentsSub {
  display: flex;
  flex: 1;
  flex-direction: row;
}
