.emailBodyStyles h1 {
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
}

.emailBodyStyles p {
  font-size: 12px;
  /* text-align: center; */
  color: palevioletred;
}

.emailBodyStyles span {
  font-size: 1.5em;
  text-align: center;
  color: rgb(255, 255, 255);
}

 