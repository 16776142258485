
.sub-1 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttons-custom {
  width:100px;
}

.sub-2{
  justify-content: space-evenly;
  display: flex;
  flex: 1;
  flex-direction: row;
}
