.dialInputArea{
  margin-top: 20px;
  margin-bottom: 20px;
}

.dial-pad {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .dialPadInput {
    width: 200px;
    height: 30px;
    margin-bottom: 20px;
    padding: 5px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .number-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .number-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 24px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .number-button:hover {
    background-color: #e1e1e1;
  }
  
  .number-button.delete {
    color: red;
  }
  
  .call-button {
    width: 200px;
    height: 40px;
    margin-top: 20px;
    font-size: 18px;
    background-color: #007aff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .call-button:hover {
    background-color: #0056b3;
  }
  