.Day {
  text-align: center;
}

.rbc-off-range-bg {
  background-color: rgba(31, 30, 30, 0.1);
}
.rbc-event-content {
  background-color: rgba(135, 135, 135, 0);
  border: 0px;
}
.rbc-event-allday {
  background-color: rgba(135, 135, 135, 0.5);
}
.rbc-event {
  /* border-top: 2px solid; */
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 5px;
  background-color: rgba(135, 135, 135, 0.1);
}
.rbc-sw {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.rbc-today {
  background-color: rgba(135, 135, 135, 0.1);
}

.rbc-month-view {
  border: 0px;
}
.rbc-month-row {
  overflow: unset;
  border: 0px solid #b9c0d2;
}

.rbc-btn-group {
  border: 0px solid #b9c0d2;
}
.rbc-toolbar button {
  border: 0px solid red;
  color: inherit;
  font-size: 0.825rem;
  border-radius: 0px;
}
.rbc-day-bg {
  border: 0px;
}
.rbc-header {
  border-color: rgba(160, 175, 185, 0.05);
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-time-view {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-time-header.rbc-overflowing {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-time-content > * + * > * {
  border: 1px solid rgba(160, 175, 185, 0.05);
}

.rbc-time-content {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-time-header-content {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-day-slot .rbc-events-container {
  margin: 0px;
}
.rbc-header + .rbc-header {
  border: 1px solid rgba(160, 175, 185, 0.05);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #d9e0ff;
  color: black;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #e3e8fd;
  color: black;
}
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-timeslot-group {
  border: 1px solid rgba(160, 175, 185, 0.05);
}
.rbc-day-slot .rbc-time-slot {
  border: 0px solid rgba(160, 175, 185, 0.05);
}
