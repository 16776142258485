/* body{
    background-color: #f4f7f6;
    margin-top:20px;
}


.card {
    background: #ffffff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
} */



/* .people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px
}

.people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer
}

.people-list .chat-list li.active {
    background: #efefef
}

.people-list .chat-list li .name {
    font-size: 15px
}

.people-list .chat-list img {
    width: 45px;
    border-radius: 50%
}

.people-list img {
    float: left;
    border-radius: 50%
}

.people-list .about {
    float: left;
    padding-left: 8px
} */

/* .people-list .status {
    color: #999;
    font-size: 13px
}

.chat .chat-header {

    border-bottom: 2px solid #f4f7f6
} */

.chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px
}



.chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff
}

.chat .chat-history ul {
    padding: 0
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px
}


.chat .chat-history .message-data {
    margin-bottom: 15px
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px
}



.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .my-message2 {
   

    background: #488cfb;

}
.chat .chat-history .my-message {
  background:  #e1e5f7;
    /* background: #efefef */
}

.chat .chat-history .my-message {
  /* Existing styles */
  /* font-weight: bold;  */
  color: black;  
  font-size: 18px;    /* Set text color to white */
  /* Rest of your styles */
  &:after {
      bottom: 100%;
      /* left: 30px; */
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      /* position: absolute; */
      pointer-events: none;
      /* border-bottom-color: #e1e5f7; */
      border-width: 10px;
      margin-left: -10px;
  }
}

.chat .chat-history .my-message2 {
  /* Existing styles */

  color: white;      /* Set text color to white */
  /* Rest of your styles */
  &:after {
      bottom: 100%;
      left: 30px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #488cfb;
      border-width: 10px;
      margin-left: -10px;
  }
}

.anim-typewriter {
    animation: typewriter 1s steps(44) 1s 1 normal both,
               blinkTextCursor 250ms steps(44) infinite normal;
               
}

  @keyframes typewriter{
    from{width: 7em;}
    to{width: 30em;}
  }
  @keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }
.btn-size {
    width: 40px; 
    height: 40px; 
    line-height: 40px; 
    margin-right: 5px;
}


.chat .chat-message {
    padding: 20px
}



.float-right {
    float: right
}



.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}



  
  .hover-fx {
    font-size: 36px;
    display: inline-block;
    cursor: pointer;
    margin: 15px;
    width: 55px;
    height: 55px;
    line-height: 84px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    color: #ffffff;
    background: #488cfb;
    transition: 300ms;
  }
  
  .hover-fx:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
    box-shadow: 0 0 0 3px #488cfb;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 300ms;
  }
  
  .hover-fx:hover {
    background-color: #488cfb;
    color: #00989A;
  }
  
  .hover-fx:hover:after {
    opacity: 1;
    transform: scale(1.15);
  }
  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }


.typing-loader{
    margin: 60px auto;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    -webkit-animation: line 1s linear infinite alternate;
   -moz-animation: line 1s linear infinite alternate;
   animation: line 1s linear infinite alternate;
}

.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}
@-webkit-keyframes line{
    0%{
       
        background-color: rgba(0,0,0, 1);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,2);
                   
      }
}

@-moz-keyframes line{
    0%{
       
        background-color: rgb(0, 0, 0);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,2);
                   
      }
}

@keyframes line{
    0%{
       
        background-color: rgba(0,0,0, 1);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
      }
    25%{ 
            background-color: rgba(0,0,0, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(0,0,0,2), 
                    24px 0px 0px 0px rgba(0,0,0,0.2);
                    
    }
    75%{ background-color: rgba(0,0,0, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(0,0,0,0.2), 
                    24px 0px 0px 0px rgba(0,0,0,2);
                   
      }

    
      
}

.chat .chat-history .message3 {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  
  position: relative;
  max-width: 200px; /* Adjusted maximum width */
  margin: 0 10px; /* Adjusted margin */
}

.chat .chat-history .my-message3:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #488cfb;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message3 {
  background: #488cfb;
}

.bubble {
  --r: 25px; /* the radius */
  --t: 30px; /* the size of the tail */
  
  max-width: 300px;
  padding: calc(2*var(--r)/3);
  -webkit-mask: 
    radial-gradient(var(--t) at var(--_d) 0,#0000 98%,#000 102%) 
      var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r),#000 75%,#0000 0) 
      calc(var(--r)/-2) calc(var(--r)/-2) padding-box, 
    radial-gradient(50% 50%,#000 98%,#0000 101%) 
      0 0/var(--r) var(--r) space padding-box;
  background: linear-gradient(135deg,#FE6D00,#1384C5) border-box;
  color: #fff;
}
.left {
  --_d: 0%;
  border-left: var(--t) solid #0000;
  margin-right: var(--t);
  place-self: start;
}
.right {
  --_d: 100%;
  border-right: var(--t) solid #0000;
  margin-left: var(--t);
  place-self: end;
}


.btn.btn-size {
  border-width: 2.5px;
}
.typing {
    
  width: 6em;
  height: 0.2em;
  position: relative;
  padding: 10px;
  margin-left: 35px;
  
  border-radius: 20px;
  

}

  
.typing__dot {
  float: left;
  width: 9px;
  height: 9px;
  margin: 0 8px;
  background: #deeaee;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.1s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}





