.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: auto !important;
  margin-left: auto !important;

  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
  justify-content: space-between;
  position: absolute;
}

.side-right {
  /* background-color: #ffffff; */
  height: 100%;
  width: 400px;
  right: 0;
  padding: 30px;
  background-color: #1b223e;

  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* Standard syntax */
}

.side-left {
  -webkit-flex: 2; /* Safari */
  -ms-flex: 2; /* IE 10 */
  flex: 2; /* Standard syntax */
  background-color: #007bff;
  /*background-image: ;*/
}

/* condition for screen size minimum of 500px */
@media (max-width: 500px) {
  /* your conditional / responsive CSS inside this condition */

  .side-left {
    -webkit-flex: 0; /* Safari */
    -ms-flex: 0; /* IE 10 */
    flex: 0; /* Standard syntax */
  }
  .side-right {
    padding: 60px;
    -webkit-flex: 1; /* Safari */
    -ms-flex: 1; /* IE 10 */
    flex: 1; /* Standard syntax */
  }
}

h4 {
  color: #b9c0d3 !important;
}

.logo-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 100px;
  width: auto;
}

/* .login-left-Image {
  display: block;
  height: 100%;
  width: 100%;
  aspect-ratio:  4 / 2;
} */


.logo-container {
  width: 100%;
}

.flex-container {
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
  justify-content: space-between;
}

.flex-container .column {
  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* Standard syntax */
}

.copyright {
  font-size: 12px;
  color: #6c757d;
  margin-top: 50px;
  /*background-color: beige !important;*/
}
