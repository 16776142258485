.waterball{
    height:250px;
}

.waterFiller{
    margin-left: 7rem;
    margin-right: 7rem;
    margin-top:5rem;
    margin-bottom: 8rem;
    padding: 0rem;
}