body 
{
    background-color:black;
    height: 100%;
    width: 100%;
    /* overflow: hidden; Hide scrollbars */
  }


 .logo{
    text-align: center;
    margin-bottom: -2px;
    margin-top: 35px;
    margin-left: 620px;
} 


  
  .text2 {
    display: inline;
    text-align: center; }

 .text2 h1 {
      color: #fff;
      font-size: 20px;
      font-family: "ubuntu";
      /* text-transform: uppercase; */
      font-weight: 300;
      font-family: "Josefin Sans", sans-serif;
      background: linear-gradient( to right, rgba(180, 171, 171, 0.24), rgb(184, 181, 181));
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: animate 10s , show 5s linear forwards, fadeIn 1s, textclip 1.5s linear infinite;
      display: inline-block;
      animation-delay: 8s;
      visibility: hidden;
      animation-fill-mode: forwards;

     }



  
 
     @keyframes textclip {
        
        to {
          background-position: -200% center;
        }
      }

      @keyframes show{
        29% {
            visibility: hidden; 
        }
        30% {
            visibility: visible;
        }
        99% {
            visibility: visible;
        }
        100% {
            visibility: hidden;
        }
      


    }
     

.loader{
    justify-content: center;
    animation: animate 5s linear forwards, fadeIn 1s; 
    animation-delay: 8s;
    visibility: hidden;
    animation-fill-mode: forwards;

    animation: show 8s linear forwards, fadeIn 1s;
    animation-delay: 5s;

}

@keyframes show{
    29% {
        visibility: hidden; 
    }
    30% {
        visibility: visible;
    }
    99% {
        visibility: visible;
    }
    100% {
        visibility: hidden;
    }
  


}

.nbut{
    margin-top: 1rem;
}

.poptext{

    color: #fff;
      font-size: 20px;
      font-family: "ubuntu";
      /* text-transform: uppercase; */
      font-weight: 300;
      font-family: "Josefin Sans", sans-serif;

      color: #fff;
    position: absolute;
    top:50px;
    margin-top: 350px ;
       display: inline-block;
       text-align: center;
       padding-bottom: 2rem;



    
}

.success{
    visibility: hidden;
    animation: tutu 13.2s linear forwards, fadeIn 1s;
}

@keyframes tutu{

    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}




.text  p{
    position: absolute;
    text-align: center;
    color: rgb(197, 195, 191);
    font-family: sans-serif;
    font-size: 12px;
    bottom: 0px; 
    opacity: 1;
    display: inline-block;
    animation: animate 5s linear forwards, fadeIn 1s; 
    animation-delay: 8s;
    visibility: hidden;
    animation-fill-mode: forwards;
    opacity: 1;
    animation: animate 5s linear forwards, fadeIn 1s; 
    animation-delay: 8s;
    visibility: hidden;
    animation-fill-mode: forwards;
}



@keyframes animate
{
    0%
    {
        opacity: 0;
        transform: rotateY(90deg);
        filter: blur(10px);
    }
    100%
    {
        opacity: 1;
        transform: rotateY(0deg);
        filter: blur(0);
    }
}


.tfs{
    animation: animate 8s linear forwards, fadeIn 5s ; 
    animation-delay: 8s;
    visibility: hidden;
    animation-fill-mode: forwards;
    } 
    


@keyframes animate
{
    0%
    {
        /* opacity: 0;
        transform: rotateY(90deg);
        filter: blur(10px); */
        visibility: hidden;
    }
    100%
    {
        /* opacity: 1;
        transform: rotateY(0deg);
        filter: blur(0); */
        visibility: visible;
    }
}
/* 
@keyframes fadeIn {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
} */



.dot{
    position:absolute;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: white;
    transform-origin: 2px 15px;
    animation:rotate;
    animation-iteration-count: infinite;
    animation-duration: 1.5s; 
}
@keyframes rotate{
    from{
        transform: rotateZ(0deg);
    }
    to{
        transform: rotateZ(360deg);
    }
}
.d2{
    animation-delay: 0.1s;
}
.d3{
    animation-delay: 0.2s;
}
.d4{
    animation-delay: 0.3s;
}
.d5{
    animation-delay: 0.4s;
}


/* yolo land------------------------------------------------------ */
/* .yolo{
    width:50%;
    display: flex;
    margin:auto;
}
.mexxar{
    width:10%;
    float: right;
    margin:auto;
}
.tfs{
    width:10%;
    float:left;
    margin:auto
} */

/* @media only screen and (max-width: 600px) {
    .poptext{

        top:50px;
        margin-top: 25%;

        padding-bottom: 2rem;
    
    
    
        
    }
  } */